body {
    background-color: darkgray;
    margin: 0;
    margin-top: 10%;
}

.logo {
    max-width: 100%;
}

.container {
    max-width: 900px;
    margin: 1rem 2rem;
}

.card-grid {
    
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}

.card {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: .25rem;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    background-color: white;
    transform-style: preserve-3d;
    transition:  150ms;
    cursor: pointer;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

.card:hover {
    --translate-y: -2px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);

}

.card.flip {
    --rotate-y: 180deg;
}

.card .front {
    left: 0;
    
}


.card .front,
.card .back {
    position: absolute;
    padding: 1rem;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}

.card .back{
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    font-size: xx-large;
    font-weight: bolder;
}

.flashcard-options{
    margin-top: .5rem;
}

.flashcard-options {
    margin-top:  .25rem;
    color: #555;
    font-size: .75rem;
}

.flashcard-option:first-child {
    margin-top: 0;
}